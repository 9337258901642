"use client"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Alert, Box, Typography } from "@mui/material"
import Button from "@mui/material-next/Button"
import { createLoginUrl } from "../../src/createLoginUrl"
import { useCurrentLocale } from "next-i18n-router/client"
import i18nConfig from "../../i18n.config"
import { useStore } from "../../store/store"
import { useMount } from "../../hooks/useMount"
import { usePathname, useRouter } from "next/navigation"

type Props = {
  searchParams: {
    login_error?: string
  }
}

export const Home = ({ searchParams }: Props) => {
  const locale = useCurrentLocale(i18nConfig)!
  const [t, i18n] = useTranslation()
  const store = useStore()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loginError = useMemo(() => searchParams.login_error, [])

  const router = useRouter()
  const pathname = usePathname()

  useMount(() => {
    // clear search params
    router.replace(pathname)
  })

  return (
    <Box
      component="main"
      sx={{
        margin: "80px 20px 16px",
        height: "100%",
        display: "flex",
        flexFlow: "column",
        aligntems: "center",
        justifyContent: "center"
      }}
    >
      {loginError && (
        <Alert
          severity="error"
          sx={{
            marginTop: 1,
            marginBottom: 3,
            textAlign: "center"
          }}
        >
          <Typography>
            {i18n.exists(`api:${loginError}`)
              ? t(`api:${loginError}`)
              : t("api:unexpected_error")}
          </Typography>
        </Alert>
      )}

      <a
        href={createLoginUrl(store.extra().browserConfig.apiBaseUrl, locale)}
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          textDecoration: "none"
        }}
      >
        <Button
          data-testid="home-login-button"
          color="primary"
          variant="filled"
          sx={{ textDecoration: "none" }}
        >
          {t("common:login_to_existing")}
        </Button>
      </a>
    </Box>
  )
}
